<template>
    <div>
        <div style="width:400px;">
            <el-form label-width="140px">
                <el-form-item label="文件:">
                    <ul class="file-list" v-if="historicalData.length > 0">
                        <li v-for="(item,index) in historicalData" :key='index' style="cursor: pointer;">
                            <span v-if="item.fileUrl" @click="showAttachment(item)">查看pdf文件</span>&nbsp;&nbsp;<span v-if="item.wordUrl" @click="showWord(item)">查看word文件</span>
                        </li>
                    </ul>
                    <div v-else>暂无文件</div>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { getProjectFirstReports } from '@/api/api'
export default {
    data() {
        return {
            historicalData: [],
        }
    },
    props:['projectId'],
    methods: {
        // 初审报告
        primaryExaminations() {
            var params = {
                projectId: this._props.projectId
            }
            this.historicalData = []
            getProjectFirstReports(params).then(res => {
                var result = res.data
                if(result.success) {
                    if(result.response.isAutograph) {
                        result.response.fileName = result.response.finalReportPath.split('/')[result.response.finalReportPath.split('/').length - 1]
                        result.response.fileUrl = result.response.finalReportPath
                        if(result.response.originalWordReportPath) {
                            result.response.wordUrl = result.response.originalWordReportPath;
                        }
                        this.historicalData.push(result.response)
                    } else {
                        result.response.fileName = result.response.originalReportPath.split('/')[result.response.originalReportPath.split('/').length - 1]
                        result.response.fileUrl = result.response.originalReportPath
                        if(result.response.originalWordReportPath) {
                            result.response.wordUrl = result.response.originalWordReportPath;
                        }
                        this.historicalData.push(result.response)
                    }
                }
            })
        },
        // 查看文件
        showAttachment(file) {
            let a = document.createElement('a');
            a.href = file.fileUrl
            a.target = 'blank';
            document.body.append(a);
            a.click();
            a.remove();
        },
        showWord(file) {
            let a = document.createElement('a');
            a.href = file.wordUrl
            a.target = 'blank';
            document.body.append(a);
            a.click();
            a.remove();
        },
    },
    watch: {
        'projectId': function(val) {
            this.primaryExaminations()
        },
    },
    created(){
        this.primaryExaminations()
    }
}
</script>

<style lang="stylus" scoped>

</style>