<template>
    <div>
        <el-table
                :data="reviewReportData"
                border
                style="width: 100%"
                v-loading="reviewTableLoading"
                ref="multipleTable"
                :highlight-current-row='true'
            >
                <el-table-column type='index' label="序号" width='60' align="center"></el-table-column>
				<el-table-column prop="userName" label="姓名" align="center"></el-table-column>
				<el-table-column prop="opinionNum" label="意见数" width='80' align="center"></el-table-column>
                <el-table-column prop="createTime" width='180'  label="生成时间" align="center" show-overflow-tooltip>
                    <template scope="scope">
                        {{formatCreateTime(scope.row.createTime?scope.row.createTime:scope.row.updateTime)}}
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100" align="center">
                    <template slot-scope="scope">
                        <el-button @click="reviewReportClick(scope.row)" type="text" size="small" >查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
    </div>
</template>

<script>
import util from "../../../util/date";
import { getTaskReviewList,getProjectReports } from '@/api/api'
export default {
    data() {
        return {
            reviewReportData: [],
            reviewTableLoading: false,
        }
    },
    props:['projectId','reportORidea'],
    methods: {
        // 图审人员初审意见
        primaryExaminations() {
            var params = {
                projectId: this._props.projectId
            }
            this.reviewTableLoading = true
            if(this._props.reportORidea == true) {
                getProjectReports(params).then(res => {
                    var result = res.data
                    if(result) {
                        this.reviewReportData = result.response
                    } else {
                        this.reviewReportData = [];
                        this.$message.error("加载失败！")
                    }
                    this.reviewTableLoading = false
                })
            } else {
                getTaskReviewList(params).then(res => {
                    var result = res.data
                    if(result) {
                        this.reviewReportData = result.response
                    } else {
                        this.reviewReportData = [];
                        this.$message.error("加载失败！")
                    }
                    this.reviewTableLoading = false
                })
            }
        },
        reviewReportClick(row) {
            this.$emit("reviewReportClick", row);
        },
        // 时间格式化
        formatCreateTime: function(row, column) {
            return !row || row == ""
            ? ""
            : util.formatDate.format(new Date(row), "yyyy-MM-dd hh:mm");
        },
    },
    watch: {
        'projectId': function(val) {
            this.primaryExaminations()
        },
        'reportORidea': function(val) {
            this.primaryExaminations()
        },
    },
    created(){
        this.primaryExaminations()
    }
}
</script>

<style lang="stylus" scoped>

</style>